<template>
  <footer class="footer">
    <div class="footer-content">
      <ul class="footer-content__list">
        <h3 class="footer-content__list--title">{{ $t("Reglas") }}</h3>
        <li class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in FooterColumn1" :key="index">
          <router-link :to="route.route">{{ route.name }}</router-link>
        </li>
      </ul>
      <ul class="footer-content__list">
        <h3 class="footer-content__list--title">{{ $t("Legal") }}</h3>
        <li class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in FooterColumn2" :key="index">
          <router-link :to="route.route">{{ route.name }}</router-link>
        </li>
      </ul>
      <!-- <ul class="footer-content__list">
        <h3 class="footer-content__list--title">Reglamentos</h3>
        <li class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in regulations" :key="index">
          <router-link :to="route.route">{{ route.name }}</router-link>
        </li>
      </ul> -->
      <ul class="footer-content__list">
        <a class="footer-content__list--btn" :href="agents" target="_blank">{{ $t("Agentes") }}</a>
        <!-- <h3 class="footer-content__list--title">Agentes</h3>
        <li class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in privacy" :key="index">
          <router-link :to="route.route">{{ route.name }}</router-link>
        </li> -->
      </ul>
    </div>
    </footer>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { watch, reactive } from 'vue'
  export default {
    name: "FooterComponent",
    setup() {
      const agents = "https://adm.apuestamacao.com/";
      const { t, locale }  = useI18n();
      const FooterColumn2 = reactive([ 
          {
            name       :  t("OpcionesfooterLegal.0.title"),
            route      :  t("OpcionesfooterLegal.0.link")
          },
          {
            name       :  t("OpcionesfooterLegal.1.title"),
            route      :  t("OpcionesfooterLegal.1.link")
          },
          {
            name       :  t("OpcionesfooterLegal.2.title"),
            route      :  t("OpcionesfooterLegal.2.link")
          }
      ])
      const FooterColumn1 = reactive([ 
          {
            name       :  t("OpcionesfooterReglas.0.title"),
            route      :  t("OpcionesfooterReglas.0.link")
          },
          {
            name       :  t("OpcionesfooterReglas.1.title"),
            route      :  t("OpcionesfooterReglas.1.link")
          },
          {
            name       :  t("OpcionesfooterReglas.2.title"),
            route      :  t("OpcionesfooterReglas.2.link")
          }
      ])
      // Observar los cambios de idioma y actualiza los slides
        const updateSlides = () => {
          FooterColumn2.forEach((opcion, index) => {
          opcion.name = t(`OpcionesfooterLegal.${index}.title`);
          opcion.route = t(`OpcionesfooterLegal.${index}.link`);
        });
          FooterColumn1.forEach((opcion, index) => {
          opcion.name = t(`OpcionesfooterReglas.${index}.title`);
          opcion.route = t(`OpcionesfooterReglas.${index}.link`);
        });
      };

      // Escuchar los cambios de idioma y actualiza los slides
      watch(locale, () => {
        updateSlides();
      });
      return {
        FooterColumn2,
        FooterColumn1,
        agents,
        routes: [
          {
            name       :  'sportsbook',
            route      :  'sportsbook'
          },
          {
            name       :  'live betting',
            route      :  'live-betting'
          },
          {
            name       :  'racebook',
            route      :  'racebook'
          },
          {
            name       :  'casino',
            route      :  'casino'
          },
          {
            name       :  'house rules',
            route      :  'house-rules'
          },
          {
            name       :  'sports rules',
            route      :  'sports-rules'
          },
        ]

        // regulations: [
        //   {
        //     name       :  'Política de reclamos',
        //     route      :  'politica-reclamos'
        //   },
        //   {
        //     name       :  'Política de anti lavado',
        //     route      :  'anti-lavado'
        //   },
        //   {
        //     name       :  'Juego justo',
        //     route      :  'juego-justo'
        //   },
        // ],
        // privacy: [
        //   {
        //     name       :  'Política de privacidad',
        //     route      :  'politica-privacidad'
        //   },
        //   {
        //     name       :  'Política de pago',
        //     route      :  'politica-pago'
        //   },
        //   {
        //     name       :  'Conozca a su cliente',
        //     route      :  'conozca-cliente'
        //   },
        // ]
      }
    },
  };
</script>
